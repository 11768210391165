exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catalog-js": () => import("./../../../src/pages/catalog.js" /* webpackChunkName: "component---src-pages-catalog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oldfaq-js": () => import("./../../../src/pages/oldfaq.js" /* webpackChunkName: "component---src-pages-oldfaq-js" */),
  "component---src-pages-oldwork-js": () => import("./../../../src/pages/oldwork.js" /* webpackChunkName: "component---src-pages-oldwork-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

