import { createRoot } from 'react-dom/client';

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    if (!(container instanceof HTMLElement)) {
      throw new Error('Container is not a valid DOM element');
    }
    createRoot(container).render(element, callback);
  };
};
